import React from "react";
import { graphql, PageProps } from "gatsby";
import { ProductDetail, StoreLayout } from "@lgastler-ba/ui";
import { ProductQuery } from "../../../graphql-types";
import { formatPrice } from "@lgastler-ba/shopify";

export default function ProductPage({ data }: PageProps<ProductQuery>) {
  const { product } = data;

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <>
      <StoreLayout>
        <ProductDetail
          pathname=""
          search=""
          product={{
            description: product.description ?? "Default Description",
            availableForSale: true,
            slug: product.handle ?? "",
            images:
              product.images?.map((image: any) => ({
                alt: "image alt",
                id: 1,
                name: "image",
                src: image.gatsbyImageData.images.fallback.src,
              })) ?? [],
            id: "id",
            href: product.id,
            imageAlt: "",
            imageHeight: product.images?.[0]?.height,
            imageWidth: product.images?.[0]?.width,
            price: formatPrice({
              amount: product.priceRangeV2?.minVariantPrice?.amount ?? "0",
              currencyCode:
                product.priceRangeV2?.minVariantPrice?.currencyCode ?? "EUR",
            }),
            name: product.title ?? "NO NAME",
            imageSrc: product.images?.[0]?.originalSrc,
          }}
        />
      </StoreLayout>
    </>
  );
}

export const query = graphql`
  query Product($handle: String!) {
    product: shopifyProduct(handle: { eq: $handle }) {
      id
      handle
      title
      description
      descriptionHtml
      options {
        id
        name
        values
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      variants {
        id
        title
        sku
        availableForSale
        selectedOptions {
          name
          value
        }
        price
      }
      images {
        originalSrc
        altText
        width
        height
        gatsbyImageData(height: 1200)
      }
    }
  }
`;
